import React from "react";
import Template from '../components/Template/Template';
import Faq from '../sections/Faq/Faq';

const Marki = () => {

    return (
        <Template path="/marka-xerox">
           
           <h1 className="ch1">Xerox - skup tonerów</h1>
            <section id="pSell" className="ccon">
                <p>Produkty tej marki znajdują się najczęściej w firmach back-office’owych. Biura tego typu zwykle kupują duże zapasy wkładów do drukarek. Bardzo często też mierzą się z problemem niepotrzebnych, ale pełnych kartridżów: szkoda je wyrzucić, ale jednocześnie nie ma konieczności wymiany starych. Co wtedy?</p>
                <p>Skup oryginalnych tonerów Xerox ma dla Państwa rozwiązanie. <a href="/#pSell">Wyceniamy</a>  produkty tej marki &ndash; przyjmiemy i egzemplarze firmowo zapakowane, i nawet te, których pudełka zostały już kiedyś otworzone. Z nami mają Państwo okazję odzyskać część kosztów, jakie ponieśliście przy zakupie zapasów.</p>
                <p>Działamy na terenie całej Polski. Skupujemy pełne tonery i w Koszalinie, i w Rudzie Śląskiej, i w każdym innym mieście &ndash; w stolicy odbieramy je od Państwa sami, w innym wypadku korzystamy z usług kurierskich.</p>
            </section>
    

        </Template>
    )
}

export default Marki
